import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { Title } from "@nerdwallet/react-typography"
import { PRODUCT_TYPES } from "@nerdwallet/nw-api-sdk/marketplace"

const Links = ({ pages }) => {
  return pages.map(({ path, title }) => (
    <p key={path}>
      <Link to={path}>{title}</Link>
    </p>
  ))
}

Links.propTypes = {
  pages: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
  }),
}

const select = accessor => value => node => accessor(node) === value
const selectCategory = select(({ primaryCategory: { name } }) => name)
const selectMarketplaceProductType = select(({ marketplaceProductType }) =>
  marketplaceProductType.toLowerCase()
)

const IndexPage = ({ data }) => {
  const {
    allSmbRoundup: { nodes: roundupPages },
    allSmbCreditCardRoundup: { nodes: ccRoundupPages },
    allSmbArticle: { nodes: allArticlePages },
  } = data

  const smallBusinessArticles = allArticlePages.filter(
    selectCategory("Small Business")
  )
  const creditCardsArticles = allArticlePages.filter(
    selectCategory("Credit Cards")
  )

  const lendingRoundupPages = roundupPages.filter(
    selectMarketplaceProductType(PRODUCT_TYPES.SMB_LOANS)
  )
  const softwareRoundupPages = roundupPages.filter(
    selectMarketplaceProductType(PRODUCT_TYPES.SOFTWARE)
  )
  const bankingRoundupPages = roundupPages.filter(
    selectMarketplaceProductType(PRODUCT_TYPES.BANKING)
  )

  return (
    <div style={{ margin: "1rem" }}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Title>Landing Pages</Title>
      <p>
        <Link to="/small-business-loans-legacy-hub">
          Small-Business Loans: A Business Owner&#x27;s Guide for 2022 (Legacy)
        </Link>
      </p>
      <hr />
      <Title>SMB Lending Roundups</Title>
      <Links pages={lendingRoundupPages}></Links>
      <hr />
      <Title>Credit Card Roundups</Title>
      <Links pages={ccRoundupPages}></Links>
      <hr />
      <Title>Software Roundups</Title>
      <Links pages={softwareRoundupPages}></Links>
      <hr />
      <Title>Banking Roundups</Title>
      <Links pages={bankingRoundupPages}></Links>
      <hr />
      <Title>Articles</Title>
      <Links pages={smallBusinessArticles}></Links>
      <hr />
      <Title>Credit Card Articles</Title>
      <Links pages={creditCardsArticles}></Links>
    </div>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    allSmbRoundup: PropTypes.shape({
      nodes: PropTypes.array,
    }),
    allSmbCreditCardRoundup: PropTypes.shape({
      nodes: PropTypes.array,
    }),
    allSmbArticle: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const query = graphql`
  query Q {
    allSmbArticle {
      nodes {
        title
        path
        primaryCategory {
          name
        }
      }
    }
    allSmbRoundup {
      nodes {
        title
        path
        marketplaceProductType
      }
    }
    allSmbCreditCardRoundup {
      nodes {
        title
        path
      }
    }
  }
`
